import React from 'react';
// Import the Login component 
import LoginForm from '../components/LoginForm/LoginForm';
function Login(props) {
  return (
    <div>
      <LoginForm />
    </div>
  );
}

export default Login;