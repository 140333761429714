import React from 'react'
import bannerSecond from "../../assets/images/custom/banner/banner2.jpg";
import leftAbout from "../../assets/images/custom/banner/aboutFirst.jpg";
import Experience from './Sections/Experience';
import WhyChooseUs from './Sections/WhyChooseUs';
import banner from "../../assets/images/custom/banner/banner1.jpg";
import Appointment from './Sections/Appointment';

function About() {
  return (
    <>
      <section className="video-section">
        <div
          data-parallax={{ y: 50 }}
          className="sec-bg"
          style={{
            backgroundImage: `url(${bannerSecond})`,
          }}
        ></div>
        <div className="auto-container">
          <h5>Working since 1999</h5>
          <h2 style={{ color: "#1365b7cf" }}>
            Tuneup Your Car <br /> to Next Level
          </h2>
        </div>
      </section>
      <section className="about-section-three">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-7">
              <div className="content">
                <h2>
                  We are highly skilled mechanics <br /> for your car repair
                </h2>
                <div className="text">
                  <p>
                    What sets us apart is our unwavering dedication to customer
                    satisfaction, reflected in every interaction and journey we
                    undertake. Our team of seasoned professionals brings a
                    wealth of experience and expertise to ensure that your
                    transportation needs are not just met, but exceeded.
                  </p>
                  <p>
                    As a locally-owned and operated business, we take great
                    pride in serving our community and fostering lasting
                    relationships with our customers.you can trust Business
                    Allstar Auto Car and Cab to deliver the highest standard of
                    service with a personal touch.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="image">
                <img src={leftAbout} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Experience />
      <WhyChooseUs />
      <section className="video-section">
        <div
          data-parallax={{ y: 50 }}
          className="sec-bg"
          style={{
            backgroundImage: `url(${banner})`,
          }}
        ></div>
        <div className="auto-container">
          <h5>Working since 2009</h5>
          <h2 style={{ color: "#1365b7cf" }}>
            Tuneup Your Car <br /> to Next Level
          </h2>
        </div>
      </section>
      <Appointment />
    </>
  );
}

export default About