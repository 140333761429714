import React from "react";

function NotFound() {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">Page Not Available</h1>
      <p className="not-found-message">
        The page you are looking for does not exist.
      </p>
    </div>
  );
}

export default NotFound;
