import React from 'react'
import leftImage from "../../../assets/images/custom/misc/vban1.jpg";
import rightImage from "../../../assets/images/custom/misc/vban2.jpg";
import { Link } from "react-router-dom";

function Experience() {
  return (
    <section className="about-section">
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-5">
            <div className="image-box">
              <img src={leftImage} alt="" />
              <img src={rightImage} alt="" />
              <div className="year-experience" data-parallax={{ y: 30 }}>
                <strong>14</strong> years <br />
                Experience
              </div>
            </div>
          </div>
          <div className="col-lg-7 pl-lg-5">
            <div className="sec-title">
              <h5>Welcome to Our workshop</h5>
              <h2>We have over 14 years experience</h2>
              <div className="text">
                <p>
                  At Business Allstar Auto Car and Cab, we pride ourselves on
                  our extensive expertise, backed by over 14 years of dedicated
                  experience in the automotive and transportation industry.
                </p>
                <p>
                  Over the years, we have honed our skills, refined our
                  services, and built lasting relationships with our valued
                  clients. Our commitment to excellence and our deep-rooted
                  knowledge ensure that every journey with us is not just a
                  ride, but an experience founded on reliability,
                  professionalism, and unparalleled expertise.
                </p>
              </div>
              <div className="link-btn mt-40">
                <Link to="/about" className="theme-btn btn-style-one style-two">
                  <span>
                    About Us <i className="flaticon-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience