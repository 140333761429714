import React from 'react'

function ServiceSection() {
  return (
    <section className="services-section">
      <div className="auto-container">
        <div className="sec-title style-two">
          <h2>Our Featured Services</h2>
          <div className="text">
            At Business Allstar Auto Car, we offer an array of premium
            services tailored to meet your every transportation need. From swift
            and reliable taxi services to meticulously maintained rental cars,
            we ensure a seamless and comfortable travel experience for our
            valued customers.
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 service-block-one">
            <div className="inner-box hvr-float-shadow">
              <h5>Service and Repairs</h5>
              <h2>Performance Upgrade</h2>
              
              <div className="icon">
                <span className="flaticon-power"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 service-block-one">
            <div className="inner-box hvr-float-shadow">
              <h5>Service and Repairs</h5>
              <h2>Transmission Services</h2>
              
              <div className="icon">
                <span className="flaticon-gearbox"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 service-block-one">
            <div className="inner-box hvr-float-shadow">
              <h5>Service and Repairs</h5>
              <h2>Break Repair & Service</h2>
              
              <div className="icon">
                <span className="flaticon-brake-disc"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 service-block-one">
            <div className="inner-box hvr-float-shadow">
              <h5>Service and Repairs</h5>
              <h2>Engine Service & Repair</h2>
              
              <div className="icon">
                <span className="flaticon-car-engine"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 service-block-one">
            <div className="inner-box hvr-float-shadow">
              <h5>Service and Repairs</h5>
              <h2>Wheels</h2>
             
              <div className="icon">
                <span className="flaticon-tire"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 service-block-one">
            {/* <div className="inner-box hvr-float-shadow">
              <h5>Service and Repairs</h5>
              <h2>Denting & Painting</h2>
              
              <div className="icon">
                <span className="flaticon-spray-gun"></span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceSection